import styled from 'styled-components'

export const LandingPage = styled.section`
  background-color: #fff;
  width: 696px;
  color: #000;
  font: 400 16px/22px 'DM Sans';
  letter-spacing: 0.16px;
`

export const LandingBranding = styled.div`
  margin: 0 auto;
  width: 200px;

  @media only screen and (min-width: 768px) {
    left: 20px;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;
    width: 200px;
  }
`

export const FullHeightContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  // background-color: #e7ff35;
`

export const HeaderContainer = styled.div`
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100%;
`

export const Header = styled.div`
  margin: 50px auto 0;
  max-width: 1220px;
  position: relative;
  width: 100%;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 auto 0;
    top: 50px;
    padding-right: 20px;
  }
`

export const HeaderNav = styled.div`
  margin: 0 auto;
  text-align: center;

  nav {
    display: block;
    line-height: 42px;
  }

  .sign-in-btn {
    padding: 0 20px;
    display: inline-block;
  }

  &:before,
  &:after {
    content: '';
    display: table;
  }

  @media only screen and (min-width: 768px) {
    float: right;
    margin: 0;
    text-align: left;

    nav {
      display: inline-block;
    }

    .sign-in-btn {
      margin-left: 24px;
    }
  }
`

export const SectionBranded = styled.section`
  padding: 300px 20px;
  text-align: center;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 42px;
    line-height: 46px;
    margin-bottom: 15px !important;
  }

  p {
    font-size: 17px;
    line-height: 24px;
  }

  h1,
  p {
    display: block;
    margin: 0 auto;
    max-width: 520px;
    position: relative;
    text-align: center;
    z-index: 1;
  }
`
