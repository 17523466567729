import React, { useEffect, useState } from 'react'
import querySting, { ParsedQuery } from 'query-string'
import { Link, RouteComponentProps } from 'react-router-dom'
import { LandingPage, Loader, Snackbar } from '../modules'
import { Logo } from '../components/icons'
import { resetPassword } from '../services/api'
import { AxiosError, HttpStatusCode } from 'axios'

const ResetPassword: React.FC<RouteComponentProps> = (props) => {
  const title = 'Reset Your Password'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [onSave, setOnSave] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: ''
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOnSave(true)
    resetPassword(token, email, password, confirmPassword)
      .then(() => {
        setOnSave(false)
        setSuccess(true)
        setError({
          status: false,
          message: ''
        })
      })
      .catch((err: AxiosError<{status: HttpStatusCode, message: {password?: Array<string>}}>) => {
        let message = 'Please check your reset password link!'
        if (err.response?.data.status === HttpStatusCode.UnprocessableEntity) {
          message = err.response.data.message.password?.[0] ?? ''
        }
        if (err.response && err.response.status === 401) {
          message = 'This reset link has expired. Please request a new link.'
        }
        setError({
          status: true,
          message: message
        })
        setSuccess(false)
        setOnSave(false)
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { currentTarget: input } = e
    switch (input.name) {
      case 'password':
        setPassword(input.value)
        break
      case 'confirmPassword':
        setConfirmPassword(input.value)
        break
      default:
        setEmail(input.value)
    }
  }
  useEffect(() => {
    const { search } = props.location || {}
    if (search) {
      const parsed: ParsedQuery = querySting.parse(search)
      setToken(String(parsed.code))
      setEmail(String(parsed.key))
    }
    document.title = title
  }, [])
  return (
    <div className='flash-section'>
      <LandingPage
        className='container login-page'
        style={{
          width: '357px',
          borderRadius: '10px',
          padding: '25px 25px'
        }}>
        <Link to='/'>
          <Logo />
        </Link>
        <h2>{title}</h2>
        {success ? (
          <p>
            Password reset successfully, please login <Link to='/'>here</Link>.
          </p>
        ) : (
          <>
            <p>Please enter your new password below.</p>
            <div className='form-contain'>
              <form className='forms' onSubmit={(e) => handleSubmit(e)}>
                <div className='form__row'>
                  <input
                    type='password'
                    placeholder='Password'
                    name='password'
                    onChange={(e) => handleInputChange(e)}
                    className='form__text-input'
                    style={{ fontSize: '14px' }}
                  />
                </div>
                <div className='form__row'>
                  <input
                    type='password'
                    placeholder='Confirm Password'
                    name='confirmPassword'
                    onChange={(e) => handleInputChange(e)}
                    className='form__text-input'
                    style={{ fontSize: '14px' }}
                  />
                </div>
                <div className='form__action form__row'>
                  <button
                    type='submit'
                    name='submit'
                    className='btn btn--action-inline btn--standard p-relative'
                    disabled={onSave}
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      fontWeight: 600,
                      height: '53px',
                      width: '100%',
                      borderRadius: '6px',
                      backgroundColor: '#3264FF80'
                    }}>
                    {!onSave ? 'Reset Password' : <Loader white />}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </LandingPage>
      <Snackbar
        type='error'
        show={error.status}
        message={error.message}
        zIndex={9999}
      />
    </div>
  )
}

export default ResetPassword
