import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountSidebar } from '../components'
import {
  SectionLoading,
  ManagementPage,
  ManagementPanel,
  ManagementPanelInner,
  Snackbar,
  ButtonStandard,
  ManagementPanelBlock,
  ButtonWithoutBorder
} from '../modules'
import { actions as sessionActions } from '../reducers/session'
import { actions as usersActions } from '../reducers/users'
import { fetchUser, loginSocial, slackConnectTeam } from '../services/api'
import { GlobalStyles } from '../styles'
import { timezones, timezone as defaultTimezone } from '../utils'
import '../modules/_styles/modal.style.css'
import { User } from '../interfaces/user'
import { RootState } from '../store'
import { actions as notificationActions } from '../reducers/notification'
import { getCurrentUser } from '../reducers/user'
import { getTeamsById } from '../reducers/teams'
import { useLocation } from 'react-router-dom'

const Account: React.FC = () => {
  const title = 'Account'
  const teams = useSelector((state: RootState) => getTeamsById(state))
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | null>(null)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const dispatch = useDispatch()
  const search = useLocation().search
  const [errors, setErrors] = useState<any>({
    firstName: '',
    lastName: '',
    displayName: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { currentTarget: input } = e
    if (user) {
      const userCopy = { ...user }
      if (input.id === 'displayName') {
        // eslint-disable-next-line camelcase
        userCopy.display_name = input.value
      } else if (input.id === 'timezone') {
        // eslint-disable-next-line camelcase
        userCopy.teamState[userCopy.selectedTeam].timezone = input.value
      } else if (input.id === 'firstName') {
        userCopy.name = `${input.value} ${user.lastName}`
      } else if (input.id === 'lastName') {
        userCopy.name = `${user.firstName} ${input.value}`
      } else if (input.id === 'password') {
        setPassword(input.value)
      } else if (input.id === 'password_confirmation') {
        setPasswordConfirmation(input.value)
      }
      // @ts-ignore
      userCopy[input.id] = input.value
      setUser(userCopy)
    }
  }
  const errMessage = useSelector(
    (state: RootState) => state.entities.users.errorMessage
  )
  const hasError = useSelector(
    (state: RootState) => state.entities.users.hasError
  )
  const currentUser = useSelector((state: RootState) => getCurrentUser(state))

  const validateUserData = ({ firstName, lastName, email, displayName }: User) => {
    let isValid: boolean = false
    const newErrors: any = {}
    if (!firstName) {
      newErrors.firstName = 'First name is required.'
    }
    if (!lastName) {
      newErrors.lastName = 'Last name is required.'
    }
    if (!displayName) {
      newErrors.displayName = 'Display name is required.'
    }
    if (!email) {
      newErrors.email = 'Email is required.'
    }
    if (!password) {
      newErrors.password = 'Password is required.'
    }
    if (password !== passwordConfirmation) {
      newErrors.passwordConfirmation = 'Passwords do not match.'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setErrors({})
      isValid = true
    }

    return isValid
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!user) {
      return
    }
    if (!validateUserData(user)) {
      return
    }
    const teamStateUpdate: { [key: string]: any } = {}
    teamStateUpdate[user.selectedTeam] = {
      timezone: user.timezone,
      displayName: user.displayName
    }
    let payload = {
      name: user.name,
      email: user.email,
      teamState: teamStateUpdate
    }
    if (password.length > 0) {
      payload = {
        ...payload,
        // @ts-ignore
        password: password,
        // eslint-disable-next-line camelcase
        password_confirmation: passwordConfirmation
      }
    }
    dispatch(usersActions.updateSingle(Number(user?.id), payload))
    setTimeout(() => {
      if (!hasError) {
        dispatch(
          notificationActions.updateSnackbar({
            show: true,
            status: 'success',
            message: 'Account Information Updated',
            timeout: 3000
          })
        )
      }
    }, 2000)
  }
  useEffect(() => {
    if (search) {
      dispatch(sessionActions.addAppSlack(search))
    }
  }, [])
  useEffect(() => {
    dispatch(sessionActions.loginSuccess())
    fetchUser()
      .then((res) => {
        const { user: userData } = res.data
        if (currentUser.selectedTeam) {
          userData.selectedTeam = currentUser.selectedTeam
        }
        if (!userData.teamState[userData.selectedTeam].timezone) {
          userData.teamState[userData.selectedTeam].timezone = defaultTimezone
        }
        if (userData.name) {
          const names = userData.name.split(' ')
          if (names.length > 1) {
            userData.lastName = names[1]
          }
          userData.firstName = names[0]
        }
        setUser(userData)
        setLoading(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('fetch-user error: ', err)
      })
  }, [currentUser.selectedTeam])

  const handleConnectSlackAccount = () => {
    if (!user) {
      return
    }
    loginSocial('slack')
      .then((res) => {
        const { url } = res.data
        window.location = url
        return
      })
      .catch(() => {
        dispatch(
          notificationActions.updateSnackbar({
            show: true,
            status: 'error',
            message: 'Failed to connect slack',
            timeout: 3000
          })
        )
      })
    return
  }

  const handleConnectSlackTeam = () => {
    if (!user) {
      return
    }
    slackConnectTeam(user.selectedTeam)
      .then((res) => {
        const { url } = res.data
        window.location = url
        return
      })
      .catch(() => {
        dispatch(
          notificationActions.updateSnackbar({
            show: true,
            status: 'error',
            message: 'Failed to connect team to slack',
            timeout: 3000
          })
        )
      })
    return
  }

  const handleConnectGoogleAccount = () => {
    if (!user) {
      return
    }
    loginSocial('google')
      .then((res) => {
        const { url } = res.data
        window.location = url
        return
      })
      .catch(() => {
        dispatch(
          notificationActions.updateSnackbar({
            show: true,
            status: 'error',
            message: 'Failed to connect google',
            timeout: 3000
          })
        )
      })
    return
  }

  const teamLinked = () => {
    const slackTeamId = teams[currentUser.selectedTeam]?.slack_team_id
    return slackTeamId && slackTeamId.length > 0
  }

  const userLinked = () => {
    return currentUser?.slack_linked
  }

  const socialLabelStyle = {
    color: '#808080',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '20x',
    margin: 0
  }

  const socialSubtitleStyle = {
    color: '#9D9D9D',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '17x'
  }

  return (
    <div className={`App show`}>
      <GlobalStyles />
      <>
        <AccountSidebar />
        <ManagementPage>
          {loading ? (
            <SectionLoading color={false} />
          ) : (
            <ManagementPanel className='management-panel'>
              <ManagementPanelInner className='management-panel__inner'>
                <ManagementPanelBlock className='management-panel__block'>
                  <h2
                    style={{
                      color: '#666666',
                      fontSize: '28px'
                    }}>
                    {title}
                  </h2>
                  <form
                    action='#'
                    method='post'
                    className='form'
                    onSubmit={(e) => handleSubmit(e)}>
                    <div className='form__row'>
                      <div className='form__row__half'>
                        <label htmlFor='first_name' className='form__label'>
                          First Name
                        </label>
                        <input
                          type='text'
                          className='form__text-input'
                          id='firstName'
                          placeholder='eg. John'
                          value={user?.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && <span className='error-message'>{errors.firstName}</span>}
                      </div>

                      <div className='form__row__half'>
                        <label htmlFor='last_name' className='form__label'>
                          Last Name
                        </label>
                        <input
                          type='text'
                          className='form__text-input'
                          id='lastName'
                          placeholder='eg. Doe'
                          value={user?.lastName}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors.lastName && <span className='error-message'>{errors.lastName}</span>}
                      </div>
                    </div>

                    <div className='form__row'>
                      <label htmlFor='display_name' className='form__label'>
                        HQ Display Name
                      </label>
                      <input
                        type='text'
                        className='form__text-input'
                        id='displayName'
                        placeholder='eg. superuser1234'
                        value={user?.displayName}
                        onChange={handleChange}
                      />
                      {errors.displayName && <span className='error-message'>{errors.displayName}</span>}
                    </div>

                    <div className='form__row'>
                      <label htmlFor='email' className='form__label'>
                        Email Address
                      </label>
                      <input
                        type='email'
                        className='form__text-input'
                        id='email'
                        placeholder='eg. example@email.com'
                        value={user?.email}
                        onChange={handleChange}
                      />
                      {errors.email && <span className='error-message'>{errors.email}</span>}
                    </div>

                    <div className='form__row'>
                      <label htmlFor='timezone' className='form__label'>
                        Timezone
                      </label>
                      <div className='skinned-wrapper'>
                        <div className='skinned-text'>
                          {
                            timezones[
                              user
                                ? user.teamState[user.selectedTeam].timezone
                                : 'UTC'
                            ]
                          }
                        </div>
                        <select
                          id='timezone'
                          value={user?.teamState[user?.selectedTeam].timezone}
                          onChange={handleChange}>
                          {Object.keys(timezones).map((tz) => {
                            return (
                              <option key={tz} value={tz}>
                                {timezones[tz]}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>

                    <div className='form__row'>
                      <label htmlFor='password' className='form__label'>
                        Change Password
                      </label>
                      <input
                        type='password'
                        className='form__text-input'
                        placeholder='eg. s3cur3P@55w0rd'
                        id='password'
                        onChange={handleChange}
                      />
                      {errors.password && <span className='error-message'>{errors.password}</span>}
                    </div>

                    <div className='form__row'>
                      <label
                        htmlFor='password_confirmation'
                        className='form__label'>
                        Confirm Change Password
                      </label>
                      <input
                        type='password'
                        className='form__text-input'
                        placeholder='eg. s3cur3P@55w0rd'
                        id='password_confirmation'
                        onChange={handleChange}
                      />
                      {errors.passwordConfirmation && <span className='error-message'>{errors.passwordConfirmation}</span>}
                    </div>

                    <div className='form__row'>
                      <ButtonStandard type='submit' className='btn'>
                        Submit
                      </ButtonStandard>
                    </div>
                  </form>
                  <h2
                    style={{
                      color: '#666666'
                    }}>
                    Connected Accounts
                  </h2>
                  <p style={socialLabelStyle}>
                    Enable login with Google on your account
                  </p>
                  {userLinked() ? (
                    <div style={socialSubtitleStyle}>
                      Your account is connected to Google.
                    </div>
                  ) : (
                    <div style={socialSubtitleStyle}>
                      To connect your account to Google
                      <ButtonWithoutBorder
                        style={{
                          ...socialSubtitleStyle,
                          textDecoration: 'underline',
                          display: 'inline'
                        }}
                        onClick={handleConnectGoogleAccount}
                        type='button'
                        className='btn'>
                        {`, click here.`}
                      </ButtonWithoutBorder>
                    </div>
                  )}

                  <p style={socialLabelStyle}>
                    Enable login with Slack on your team
                  </p>
                  {userLinked() ? (
                    <div style={socialSubtitleStyle}>
                      Your account is connected to Slack. To reauthorize your
                      account
                      <ButtonWithoutBorder
                        disabled
                        style={{
                          ...socialSubtitleStyle,
                          textDecoration: 'underline',
                          display: 'inline'
                        }}
                        onClick={handleConnectSlackAccount}
                        type='button'
                        className='btn'>
                        , click here.
                      </ButtonWithoutBorder>
                    </div>
                  ) : (
                    <div style={socialSubtitleStyle}>
                      To connect your account to Slack
                      <ButtonWithoutBorder
                        style={{
                          ...socialSubtitleStyle,
                          textDecoration: 'underline',
                          display: 'inline'
                        }}
                        onClick={handleConnectSlackAccount}
                        type='button'
                        className='btn'>
                        , click here.
                      </ButtonWithoutBorder>
                    </div>
                  )}
                  <p style={socialLabelStyle}>
                    Did you know you can start HQ Remote calls from and send HQ
                    snaps to any Slack channel?
                  </p>
                  <p style={socialLabelStyle}>
                    Check out our
                    <a style={socialLabelStyle} href='#guide'>
                      {` Slack Guide`}
                    </a>{' '}
                    for more information.
                  </p>
                  {teamLinked() ? (
                    <div style={socialSubtitleStyle}>
                      Your team is connected to Slack. To reauthorize your team
                      <ButtonWithoutBorder
                        disabled
                        type='button'
                        className='btn'
                        onClick={handleConnectSlackTeam}
                        style={{
                          ...socialSubtitleStyle,
                          textDecoration: 'underline',
                          display: 'inline'
                        }}>
                        , click here.
                      </ButtonWithoutBorder>
                    </div>
                  ) : (
                    <div>
                      <ButtonWithoutBorder
                        onClick={handleConnectSlackTeam}
                        type='button'
                        className='btn'>
                        Connect Team To Slack
                      </ButtonWithoutBorder>
                    </div>
                  )}
                </ManagementPanelBlock>
              </ManagementPanelInner>
            </ManagementPanel>
          )}
          <Snackbar
            zIndex={40040}
            type='error'
            show={hasError}
            message={errMessage}
          />
        </ManagementPage>
      </>
    </div>
  )
}

export default Account
