import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { CSSProperties, ThemeProvider, css } from 'styled-components'
import EndCallIcon from '../assets/img/call-leave-chathead.svg'
import FullScreenIcon from '../assets/img/fullscreen.svg'
import KnockV2Icon from '../assets/img/knock_v2.svg'
import SnapshotIcon from '../assets/img/photo.svg'
import StartCallIcon from '../assets/img/video.svg'
import PinIcon from '../assets/img/pin.svg'
import UnpinIcon from '../assets/img/unpin.svg'
import KnockReceiveIcon from '../assets/img/knock_receive.svg'
import { CALL_STATE, layouts } from '../constants/call'
import { UserAvailabilityState, UserColor } from '../constants/user'
import { User } from '../interfaces/user'
import { CallState, getActiveSpeaker, getActiveSpeakerScreen } from '../reducers/call'
import { getCurrentUserLayout } from '../reducers/user'
import { getSingleUser } from '../reducers/users'
import { RootState } from '../store'
import CallBar from './CallBar'
import { animations, darkTheme, findColorIndex, getCallRoomIndex } from './Helpers'
import '../modules/_styles/video.css'

import { getSingleTeam } from '../reducers/teams'
import Status from './Status'
import Metadata from './Metadata'
import {
  getChatheadNotification,
  actions as chatheadNotificationAction
} from '../reducers/chatheadNotification'
import { DOM_ID } from '../constants/elementIds'
import { TrackDetail } from '../contexts/stream'
import ChatHeadPhoto from './ChatHeadPhoto'

interface ContainerStyledInterface extends CSSProperties {
  photo?: string
}

export const ContainerStyled = styled.div < ContainerStyledInterface > `
  background-image: ${(props) => props.photo ? `url("${props.photo}")` : 'none'};
  background-color: rgb(68,68,68);
  background-size: cover;
  background-position: center center;
  color: ${(props) => props.theme.foregroundColor};
  cursor: pointer;
  float: left;
  overflow: hidden;
  position: relative;
  transition: transform 200ms ease-in-out;

  &:before {
    content: '';
    display: block;
    padding: 100% 0 0;
  }
`

interface ContainerProps extends React.ComponentProps<any> {
  photo?: string;
  style: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = (props) => {
  return (
    <ContainerStyled {...props}>{props.children}</ContainerStyled>
  )
}

const ActionIconContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`

const PinIconContainerStyled = styled.div`
  z-index: 10;
  position: absolute;
  cursor: alias;
  top: 47px;
  left: 47px;
`

const ActionIconContainer: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <ActionIconContainerStyled>
      {props.children}
    </ActionIconContainerStyled>
  )
}

const PinIconContainer: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <PinIconContainerStyled>
      {props.children}
    </PinIconContainerStyled>
  )
}

interface ActionIconProps {
  smallChathead?: boolean;
}

const ActionIconStyled = styled.img < ActionIconProps > `
  ${(props) => props.smallChathead ? css`
  height: 21px;
  width: 21px;
  ` : css`
    height: 35px;
    width: 35px;
    @media (min-width: 400px) {
      height: 44px;
      width: 44px;
    }
    @media (min-width: 600px) {
      height: 50px;
      width: 50px;
    }
    @media (min-width: 768px) {
      height: 66px;
      width: 66px;
    }
  `}}
`

const ActionIcon: React.FC<React.ImgHTMLAttributes<any> | ActionIconProps> = (props) =>{
  return <ActionIconStyled {...props} />
}

type ActionOverlayStyledProps = {
  self: boolean;
  isFullscreen: boolean;
  backgroundColor: string;
}

const ActionOverlayStyled = styled.div < ActionOverlayStyledProps > `
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  &:hover {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #${(props) => (props.self || props.isFullscreen) ?
    'transparent' :
    props.backgroundColor + 'B3'};
    animation: ${animations.fadeIn} 200ms 0s 1 forwards;
  };
`

const ActionOverlay: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <ActionOverlayStyled
      backgroundColor={props.backgroundColor}
      self={props.self}
      isFullscreen={props.isFullscreen}
    >
      {props.children}
    </ActionOverlayStyled>
  )
}

const IncomingActionOverlayStyled = styled.div < ActionOverlayStyledProps > `
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #${(props) => (props.self || props.isFullscreen) ?
    'transparent' :
    props.backgroundColor + 'B3'};
  animation: ${animations.fadeIn} 200ms 0s 1 forwards;
`

const IncomingActionOverlay: React.FC<React.PropsWithChildren<any>> = (props) => {
  return (
    <IncomingActionOverlayStyled
      backgroundColor={props.backgroundColor}
      self={props.self}
      isFullscreen={props.isFullscreen}
    >
      {props.children}
    </IncomingActionOverlayStyled>
  )
}

export const MetadataContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-top: -80px;
  height: 80px;
  overflow: hidden;
  width: 100%;
`

export const initialState = {
  time: false,
  stateTime: null
}
interface ChatHeadProps extends React.PropsWithChildren<any> {
  focused?: boolean;
  myself: boolean;
  me: User|null;
  user?: User|null;
  call: CallState|null;
  layout: number;
  timezone: string;
  hideDetails: boolean;
  statusMessage: string;
  isIdle: boolean;
  idleSince: number;
  awaySince: number;
  state: string;
  name: string;
  photo: string;
  onClick: (
    e: React.MouseEvent,
    id: number,
    isConnected: boolean,
    connecting: boolean,
    state: string,
    isIdle: boolean,
    me: User|null,
    username: string
  ) => void;
  handlePin: (
    e: React.MouseEvent,
    id: number
  ) => void,
  isScreen: boolean;
  offline?: boolean;
  manualSnap?: boolean;
  pin: boolean;
  showPin: boolean;
  tracks: Array<TrackDetail>;
  snapShotRef: RefObject<HTMLVideoElement>
  isOnPip: boolean
}


const ChatHead: React.FC<ChatHeadProps> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const screenRef = useRef<HTMLVideoElement>(null)
  const currentUserLayout = useSelector((state: RootState) => getCurrentUserLayout(state))
  const [colorIndex, setColorIndex] = useState<number>(1)
  const [isConnecting, setIsConnecting] = useState<boolean|null>(null)
  const [isKnocking, setIsKnocking] = useState<boolean>(false)
  const [actionIconSrc, setActionIconSrc] = useState<string>(SnapshotIcon)
  const [showChatheadNotification, setShowChatheadNotification] = useState<boolean>(false)
  const [isInCall, setIsInCall] = useState<boolean|null>(null)
  const [isScreenRendered, setIsScreenRendered] = useState(false)
  const [isVideoRendered, setIsVideoRendered] = useState(false)
  const user: User = useSelector((state: RootState) => getSingleUser(state, props.id))
  const activeSpeaker = useSelector((state: RootState) => getActiveSpeaker(state))
  const activeSpeakerScreen = useSelector((state: RootState) => getActiveSpeakerScreen(state))
  const currentTeamState = useSelector((state: RootState) =>
    getSingleTeam(state, props.me?.selectedTeam || 0))
  const chatheadNotification = useSelector((state: RootState) => getChatheadNotification(state))
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.tracks.length === 0) {
      setIsScreenRendered(false)
      setIsVideoRendered(false)
      return
    }

    props.tracks.forEach(({ track })=> {
      if (!track) {
        return
      }

      const element = (() => {
        let e: HTMLVideoElement | HTMLAudioElement | null = null
        if (props.isScreen) {
          if (track.getVideoType() === 'desktop') {
            e = screenRef.current
          }
        } else {
          e = track.getType() === 'video' && track.getVideoType() !== 'desktop'
            ? videoRef.current
            : audioRef.current
        }
        return e
      })()

      if (!element) {
        return
      }

      if (!track.disposed && !track.isMuted()) {
        const isAttached = !props.isOnPip ? track.containers.length === 0 : track.containers.length <= 1
        if (isAttached) {
          track.attach(element)
          if (props.isScreen && track.getVideoType() === 'desktop') {
            setIsScreenRendered(true)
          }
          if (track.getVideoType() === 'camera') {
            setIsVideoRendered(true)
          }
        }
        return
      }

      if (track.isMuted() || track.disposed) {
        track.detach(element)
        if (props.isScreen && track.getVideoType() === 'desktop') {
          setIsScreenRendered(false)
        }
        if (track.getVideoType() === 'camera') {
          setIsVideoRendered(false)
        }
      }
    })
  }, [props.tracks])

  useEffect(() => {
    if (props.myself) {
      setIsInCall(!!props.call && props.call.isConnected)
      return
    }
    if (props.me) {
      const myTeamState = props.me.teamState[props.me.selectedTeam]

      if (myTeamState.callState === CALL_STATE.IN_CALL) {
        if (myTeamState.callRoom === props.call?.room) {
          setIsInCall(props.call?.isConnected)
          return
        }
      }
    }
    setIsInCall(false)
  }, [props.me, props.call, props.myself])

  const showCallbar = () => {
    let connecting = isConnecting
    let show = connecting !== null
    const myTeamState = props.me?.teamState[props.me.selectedTeam]
    if (
      props.call?.isConnected && (
        (props.layout === layouts.FULL_SCREEN && props.call.room === myTeamState?.callRoom) || (props.myself && currentUserLayout === layouts.FULL_SCREEN)
      )
    ) {
      show = false
    }

    if (props.call === null || props.call.room !== myTeamState?.callRoom || props.isScreen) {
      connecting = false
    }
    return show ? (
      <CallBar
        connecting={connecting}
        color={colorIndex ? UserColor[colorIndex].split('_')[1] : 1}
      />
    ) : <></>
  }
  useEffect(() => {
    let iconSrc = SnapshotIcon
    if (props.myself && !props.isScreen) {
      if (props.call?.isConnected) {
        iconSrc = EndCallIcon
      }
    } else if (props.call?.isConnected) {
      iconSrc = props.me?.callState === CALL_STATE.NOT_IN_CALL ?
        StartCallIcon : (
          props.layout !== layouts.FULL_SCREEN ? FullScreenIcon : ''
        )
      if (props.me?.teamState[props.me.selectedTeam].callRoom !== props.call.room) {
        iconSrc = ''
      }
    } else if (
      props.state === UserAvailabilityState.BUSY ||
      props.state === UserAvailabilityState.GHOSTING
    ) {
      iconSrc = KnockV2Icon
    } else {
      iconSrc = StartCallIcon
    }
    setActionIconSrc(iconSrc)
  }, [props.call?.isConnected, props.state, props.me?.callState, props.myself, props.layout])

  useEffect(() => {
    const teamId = props.me?.selectedTeam || 0
    // No call
    if (
      props.call === null ||
      props.call.room === ''
    ) {
      setIsConnecting(null)
      return
    }
    // New joiner connecting
    if (!props.myself && props.me) {
      setIsConnecting(
        props.me.teamState[teamId].callState === CALL_STATE.JOINING_CALL
      )
      return
    }

    // Host joining
    if (props.myself && props.me) {
      setIsConnecting(
        [CALL_STATE.JOINING_CALL]
          .includes(props.me.teamState[teamId].callState)
      )
      return
    }

    // Others joining
    if (props.user && teamId > 0) {
      setIsConnecting(props.user.teamState[teamId].callState
        === CALL_STATE.JOINING_CALL &&
        props.call.room !== props.me?.teamState[teamId].callRoom
      )
      return
    }

    setIsConnecting(false)
  }, [props.callConnectionState, props.call, props.me, props.myself, user.callState])

  useEffect(() => {
    const teamId = props.me?.selectedTeam || 0
    // Host In Call
    if (
      props.myself &&
      (props.call?.isConnected || props.me?.teamState[teamId].callState === CALL_STATE.JOINING_CALL)
    ) {
      setColorIndex(getCallRoomIndex(props.me?.teamState[teamId].callRoom || '', false))
      return
    }
    // Others In Call
    if (props.user && [CALL_STATE.IN_CALL, CALL_STATE.JOINING_CALL]
      .includes(props.user?.teamState[teamId].callState)) {
      setColorIndex(getCallRoomIndex(props.user?.teamState[teamId].callRoom || '', false))
      return
    }

    // Others Not In Call
    if (props.me && props.me.teamState[teamId].callState === CALL_STATE.IN_CALL) {
      setColorIndex(getCallRoomIndex(props.me?.teamState[teamId].callRoom || '', false))
      return
    }

    if (user.teamState[teamId].callRoom) {
      setColorIndex(getCallRoomIndex(user.teamState[teamId].callRoom || '', false))
      return
    }
    setColorIndex(currentTeamState ? findColorIndex(currentTeamState.calls) : 1)
  }, [props.call?.isConnected, props.me, props.user, user.teamState, currentTeamState])

  useEffect(() => {
    if (isKnocking) {
      setTimeout(() => {
        setIsKnocking(false)
      }, 3000)
    }
  }, [isKnocking, props.state, props.myself])

  useEffect(() => {
    if (chatheadNotification?.show && chatheadNotification?.fromUserId === props.id) {
      setShowChatheadNotification(true)
      setTimeout(() => {
        setShowChatheadNotification(false)
        dispatch(chatheadNotificationAction.updateChatheadNotification({
          ...chatheadNotification,
          show: false
        }))
      }, chatheadNotification.timeout || 3000)
    }
  }, [chatheadNotification?.show])

  const activeFullScreen = props.layout === layouts.FULL_SCREEN &&
    (
      (!props.isScreen && props.id === Number(activeSpeaker) && activeSpeakerScreen === null) ||
      (props.isScreen && props.id === Number(activeSpeakerScreen))
    ) &&
    isInCall

  const isSmallChathead =
    props.call?.isConnected &&
    currentUserLayout === layouts.FULL_SCREEN &&
    props.layout !== layouts.FULL_SCREEN

  const canvasStyles: CSSProperties = props.isScreen ? {
    objectFit: 'scale-down',
    zIndex: 1
  } : {
    transform: `scaleX(${props.myself ? '-1' : '1'})`,
    zIndex: 1
  }

  const isFullScreen = props.layout === layouts.FULL_SCREEN
  // const selfCallVideo = useSelector((state: RootState) => getCallVideo(state))
  const isShowScreen = props.isScreen && isScreenRendered
  return (
    <ThemeProvider theme={darkTheme}>
      <Container
        style={{
          ...props.style,
          ... (props.isScreen) && {
            display: (isShowScreen) ? 'block' : 'none'
          }
        }}
      >
        { !props.myself &&
        activeFullScreen &&
        props.showPin &&
        (
          (props.isScreen) ||
          (!props.isScreen && activeSpeakerScreen === null)
        ) && (
          <PinIconContainer>
            <img
              onClick={(e: React.MouseEvent) => {
                props.handlePin(e, props.id)
              }}
              height='30px' width='30px' src={props.pin ? PinIcon : UnpinIcon} />
          </PinIconContainer>
        ) }
        <div
          onClick={(e: React.MouseEvent) => {
            const myTeamState = props.me?.teamState[props.me.selectedTeam]
            if (
              myTeamState &&
              props.call?.isConnected && myTeamState.callState === CALL_STATE.IN_CALL &&
              myTeamState.callRoom !== props.call.room
            ) {
              return
            }

            if (props.isOnPip && !props.myself) {
              return
            }

            props.onClick(
              e,
              props.id,
              props.call ? props.call.isConnected : false,
              isConnecting === null ? false : isConnecting,
              props.state,
              props.isIdle,
              props.me,
              props.name
            )
            if (
              !props.myself &&
              !props.call?.isConnected &&
              props.state === UserAvailabilityState.BUSY
            ) {
              setIsKnocking(true)
            }
          }}
        >
          <ChatHeadPhoto
            src={props.photo}
            isMySelf={props.myself}
            isHidden={isVideoRendered || isScreenRendered}
          />
          {
            props.isScreen ?
              <video
                id={props.myself ? DOM_ID.SCREEN_SHARE_VIDEO : `${DOM_ID.SCREEN_SHARE_VIDEO}-${props.id}`}
                ref={screenRef}
                autoPlay
                // @ts-ignore
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  objectFit: 'scale-down',
                  ...canvasStyles
                }}
                muted
              />
              : <>
                {props.myself && (
                  <video
                    id={DOM_ID.SNAPSHOT}
                    ref={props.snapShotRef}
                    // @ts-ignore
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      visibility: (
                        props.myself && !props.call?.isConnected && props.manualSnap
                      ) ? 'visible' :
                        'hidden',
                      // @ts-ignore
                      objectFit: 'cover',
                      ...canvasStyles
                    }}
                    autoPlay
                  />
                )}
                <video
                  id={props.myself ? DOM_ID.SELF_CALL : `${DOM_ID.SELF_CALL}-${props.id}`}
                  ref={videoRef}
                  autoPlay
                  // @ts-ignore
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    display: isVideoRendered ? 'block' : 'none',
                    // @ts-ignore
                    objectFit: 'cover',
                    ...canvasStyles
                  }}
                />
                <audio
                  ref={audioRef}
                  id={`audio-${props.id}`}
                  autoPlay
                />
              </>
          }
          {(!isInCall || !props.hideDetails) && (
            <Status
              state={props.state}
              isIdle={props.isIdle}
              offline={props.offline || false}
              awaySince={props.awaySince}
              idleSince={props.idleSince === null ? 0 : props.idleSince * 1000}
            />
          )}

          {!props.disableActions && props.state !== UserAvailabilityState.AWAY && !props.isIdle && (
            <ActionOverlay
              backgroundColor={UserColor[colorIndex]
                ? UserColor[colorIndex].split('_')[1] : 'ffffff'}
              self={props.myself && !props.call?.isConnected}
              isFullscreen={props.layout === layouts.FULL_SCREEN && props.call?.isConnected}
            >
              <ActionIconContainer>
                { actionIconSrc &&
                  <ActionIcon smallChathead={isSmallChathead} src={actionIconSrc} />
                }
                {
                  !isSmallChathead && isKnocking && (
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bolder',
                        marginTop: '10px'
                      }}>
                      { isKnocking ? `Knocking for ${props.name}...` : '' }
                    </p>
                  )
                }
              </ActionIconContainer>
            </ActionOverlay>
          )}
          {
            showChatheadNotification && (
              <IncomingActionOverlay
                backgroundColor={UserColor[colorIndex].split('_')[1]}
                self={props.myself}
                isFullscreen={props.layout === layouts.FULL_SCREEN}
              >
                <ActionIconContainer>
                  <ActionIcon
                    smallChathead={isSmallChathead}
                    src={KnockReceiveIcon} />
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      marginTop: '10px'
                    }}>
                    {chatheadNotification.message}
                  </p>
                </ActionIconContainer>
              </IncomingActionOverlay>
            )
          }

          {props.children}

          {((!props.isScreen && !isInCall) || (isShowScreen && !isFullScreen) || !props.hideDetails) && (
            <Metadata
              isSmallChathead={isSmallChathead || false}
              state={props.state}
              statusMessage={props.statusMessage}
              name={props.name}
              timezone={props.timezone}
              muted={
                props.me?.selectedTeam &&
                props.user?.teamState[props.me?.selectedTeam].callState === CALL_STATE.IN_CALL &&
                props.tracks?.find(ut => ut.track?.getType() === 'audio')?.track?.isMuted() || false
              }
            />
          )}
          { showCallbar() }
        </div>
      </Container>
    </ThemeProvider>
  )
}

ChatHead.defaultProps = {
  onClick: () => {},
  call: null,
  hideDetails: false,
  myself: false,
  statusMessage: '',
  isIdle: false,
  idleSince: 0,
  awaySince: 0,
  state: UserAvailabilityState.AVAILABLE,
  name: 'user',
  photo: '',
  timezone: 'UTC',
  me: null,
  isScreen: false,
  pin: false
}

export default ChatHead
