import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { getTeamsAlphabetically } from '../reducers/teams'
import { actions as userActions, getCurrentUser, getSelectedTeamId } from '../reducers/user'
import { actions as uiActions } from '../reducers/ui'
import { RootState } from '../store'
import { CALL_STATE } from '../constants/call'
import { getNotificationCounts } from '../reducers/notification'
import { NotificationCount } from './NotificationBar'

const TeamButtonList = styled.ul`
  background: ${props => props.theme.background};
  box-sizing: border-box;
  box-shadow: 3px 0px 17px 0px rgba(50, 50, 50, 0.45);
  min-height: 100%;
  left: 0;
  margin: 0;
  padding: 10px;
  position: relative;
  top: 0;
  z-index: 100;
  width: 80px;

  li {
    list-style: none;
    display: block;
  }
`

TeamButtonList.defaultProps = {
  theme: {
    background: '#444'
  }
}

interface TeamButtonProps {
  selected: boolean;
  background: string;
}

const TeamButton = styled.button < TeamButtonProps > `
  background: ${(props) => {
    if (props.background && props.background.length > 0) {
      return `url(${props.background})`
    }
    return props.theme.background
  }};
  background-size: contain;
  border-radius: 40px;
  cursor: pointer;
  display: block;
  font-size: 25px;
  font-weight: bold;
  height: 60px;
  opacity: 0.5;
  margin: 0 0 10px;
  text-align: center;
  transition: background 100ms ease, color 100ms ease, opacity 100ms ease;
  width: 60px;

  ${props => props.selected && css`
    border-color: whitesmoke;
    box-shadow: 0 0 10px #9ecaed;
    color: white;
    opacity: 1;
  `}
  &:hover {
    opacity: 1;
  }
`

TeamButton.defaultProps = {
  theme: {
    background: '#ccc'
  }
}

const TeamSwitcher: React.FC = () => {
  const me = useSelector((state: RootState) => getCurrentUser(state))
  const teams = useSelector((state: RootState) => getTeamsAlphabetically(state))
  const selectedTeamId = useSelector((state: RootState) => getSelectedTeamId(state))
  const notificationCounts = useSelector(
    (state: RootState) => getNotificationCounts(state))
  if (Object.keys(teams).length < 2) {
    return null
  }
  const dispatch = useDispatch()
  // eslint-disable-next-line no-console
  const teamButtons = (Object.values(teams) || []).map((team, i) => {
    const selected = selectedTeamId === team.id
    return (
      <li key={`team${i}`}>
        { (!selected && notificationCounts[team.id] > 0) && (
          <NotificationCount
            notificationCount={notificationCounts[team.id]}
            style={{
              zIndex: 2
            }}
          />
        )}
        <TeamButton
          title={team.name}
          selected={selected}
          disabled={me.callState === CALL_STATE.IN_CALL}
          onClick={() => (selected ?
            dispatch(uiActions.toggleOfficeList()) :
            dispatch(userActions.switchTeam(team.id))
          )}
          background={team.logo}
        >
          {!team.logo || team.logo.length === 0
            ? team.initials.length > 3
              ? team.initials.substring(0, 3)
              : team.initials
            : ''}
        </TeamButton>
      </li>
    )
  })

  return (
    <TeamButtonList>
      {teamButtons}
    </TeamButtonList>
  )
}

export default TeamSwitcher
