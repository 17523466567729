import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InputForm, Loader, Snackbar } from '../modules'
import { forgotPassword } from '../services/api'
import { AxiosError, HttpStatusCode } from 'axios'

const ForgotPassword: React.FC = () => {
  const [sending, setSending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: ''
  })
  const title = 'Forgot Your Password?'
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { currentTarget: input } = e
    setEmail(input.value)
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSending(true)
    forgotPassword(email)
      .then(() => {
        setSuccess(true)
        setSending(false)
        setError({
          status: false,
          message: ''
        })
      })
      .catch(
        (
          err: AxiosError<{
            status: HttpStatusCode
            message: { email?: string }
          }>
        ) => {
          let message =
            'Please sorry, unable to submit your request. Please contact our administrator.'
          if (
            err.response?.data.status === HttpStatusCode.UnprocessableEntity
          ) {
            message = err.response.data.message.email ?? ''
          }

          if (err.response?.status === HttpStatusCode.NotFound) {
            message = 'Password reset not allowed for inactive accounts. Contact support.'
          }

          setSending(false)
          setError({
            status: true,
            message
          })
        }
      )
  }
  useEffect(() => {
    document.title = title
  }, [])
  return (
    <div className='flash-section'>
      <div
        style={{
          width: '696px'
        }}
        className='container login-page'>
        <p
          style={{
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            color: '#fff'
          }}>
          {title}
        </p>
        {success ? (
          <p
            style={{
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 500,
              color: '#fff'
            }}>
            Please check your email for a link to reset your password.
          </p>
        ) : (
          <>
            <p
              style={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 700,
                color: '#fff'
              }}>
              Please enter your email below and we will send a reset link to
              you, please follow the instructions in the email you receive.
            </p>
            <div className='form-contain'>
              <form className='forms' onSubmit={(e) => handleSubmit(e)}>
                <div className='form__row'>
                  <InputForm
                    type='email'
                    placeholder='Email Address'
                    name='email'
                    onChange={(e) => handleInputChange(e)}
                    className='form__text-input'
                  />
                </div>
                <div className='form__action form__row'>
                  <button
                    type='submit'
                    name='submit'
                    className='btn'
                    style={{
                      width: '100%',
                      border: '2px solid #fff',
                      borderRadius: '0px',
                      color: '#fff',
                      lineHeight: '24px',
                      fontWeight: 700
                    }}
                    disabled={sending}>
                    {!sending ? 'Send Email' : <Loader white />}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
        <p
          style={{
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            color: '#fff'
          }}>
          <Link to='/'>Back to login.</Link>
        </p>
      </div>
      <Snackbar
        type='error'
        show={error.status}
        message={error.message}
        zIndex={9999}
      />
    </div>
  )
}

export default ForgotPassword
